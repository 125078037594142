import { createApp } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
import router from "./router";
import Toaster from "@meforma/vue-toaster";
import "bootstrap";
import Moment from "moment";
import "moment/src/locale/fr";
import "v3-infinite-loading/lib/style.css";

Moment.locale("fr");

createApp(App).use(router).use(createPinia()).use(Toaster).mount("#app");
