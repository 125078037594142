<template>
  <form @submit.prevent="login()">
    <h1>Connexion</h1>

    <label>
      E-mail<br />
      <input type="email" v-model="email" />
    </label>

    <label>
      Password<br />
      <input type="password" v-model="password" />
    </label>

    <input type="submit" value="Se connecter" class="btn btn-primary" />

    <p v-if="error" class="text-danger mt-2">{{ error }}</p>
  </form>
</template>

<script>
import axios from "axios";
import { useUserStore } from "@/stores/user";
import { environment } from "../../environment";

export default {
  name: "Login",
  data() {
    return {
      error: null,
      email: "",
      password: "",
    };
  },
  setup() {
    const userStore = useUserStore();
    return {
      userStore,
    };
  },
  mounted() {
    this.userStore.logout();
  },
  methods: {
    login() {
      this.error = "";
      axios
        .post(environment.apiURL + "auth/local", {
          identifier: this.email,
          password: this.password,
        })
        .then((response) => {
          this.userStore.setUser(response.data.user);
          this.userStore.setToken(response.data.jwt);
          this.$router.push("/dashboard");
        })
        .catch(() => {
          this.error = "Les identifiants entrés sont incorrectes.";
        });
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  margin: auto;
  width: 500px;
  max-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  input[type="submit"] {
    margin-top: 15px;
  }
}
</style>
